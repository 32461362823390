<template>
  <div class="apply-page">asdas</div>
</template>
<script>
import { api } from '../api/base';
export const accessListByAction = api()('access.list.by.action.json');
export default {
  data () {
    return {
      list: [],
      dialogVisible: true,
      form: {
        resource: '',
        desc: ''
      }
    }
  },
  async mounted () {
    console.log(this.$route.query.action);
    this.list = await accessListByAction({ action: this.$route.query.action })
  },
  methods: {
    onSubmit () {
      console.log('submit!');
    }
  }
}
</script>
<style scoped>
.apply-page {
  width: 1024px;
  margin: 0 auto;
  background: #fff;
  overflow: hidden;
}
</style>